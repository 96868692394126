<template>
    <el-dialog
        title="确定要删除吗？"
        :visible.sync="visibleSync"
        width="480px"
        append-to-body
        class="delete-confirm-dialog"
        :class="`${showTab}_dialog`"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        center
    >
        <template #title>
            <slot name="title">
                <div class="header-title" :class="`${showTab}_title`">
                    <div
                        class="id-role"
                        :style="{
                            left: '170px',
                        }"
                    >
                        确定要删除吗？
                    </div>
                    <img
                        :src="popupRight"
                        class="pop-right"
                        alt=""
                        width="150"
                    />
                </div>
            </slot>
        </template>
        <div class="ticket_div">
            {{ deleteMsg }}
        </div>
        <DialogFooter
            :showTab="showTab"
            @confirm="handleConfirm"
            @cancel="handleClose"
            :loading="loading"
        />
    </el-dialog>
</template>
<script>
import DialogFooter from "../layout/DialogFooter.vue";
export default {
    name: "delete-confirm-dialog",
    data() {
        return {
            visibleSync: false, // 是否显示弹窗
            deleteMsg: "",
            deleteVoid: null,
            callBack: null,
            showTab: "NB",
            loading: false,
            successMsg: "删除成功",
            popupRight: require("@/assets/img/login/pupup_right.png"),
        };
    },
    components: {
        DialogFooter,
    },

    methods: {
        handleClose() {
            this.visibleSync = false;
        },
        handleConfirm() {
            this.visibleSync = false;
            this.loading = true;
            this.deleteVoid().then(() => {
                this.loading = false;
                this.$message({
                    message: this.successMsg,
                    type: "success",
                });
                this.callBack && this.callBack();
            });
        },
        open({
            msg,
            deleteVoid,
            callBack,
            showType = "NB",
            successMsg = "删除成功",
        }) {
            // 显示
            this.visibleSync = true;
            this.deleteMsg = msg;
            this.deleteVoid = deleteVoid;
            this.callBack = callBack;
            this.showTab = showType;
            this.successMsg = successMsg;
        },
        cancel() {
            // 关闭
            this.visibleSync = false;
        },
    },
};
</script>
<style scoped lang="stylus">
.delete-confirm-dialog{
    .header-title{
        background: linear-gradient(to right, #de5d40, #e5917e, #daacb9);
        height:50px;
        border-radius:10px 10px 0 0;
        position:relative;
        .id-role{
            position:absolute;
            left:200px;
            color:white;
            top:10px;
            font-size:24px;
            display:flex;
            flex-direction:column;
            align-items:flex-start;
        }
        .pop-right{
            position:absolute;
            right:0;
        }
        .small-delete{
            left:-60px;
            position:absolute;
            top:-80px;
            width:150px;
        }
}
/deep/ .el-dialog{
    border-radius: 10px;
}
/deep/.el-dialog__header {
    padding: 0
  }
  /deep/.el-dialog__body {
    text-align: initial;
    padding: 30px 25px 30px;
    border-radius: 10px;
    margin-top: -15px;
  }
  /deep/ .el-dialog__headerbtn{
    top:10px;
    font-size: 24px;
  }
   /deep/.el-dialog__close{
    color:#fff !important;
  }
}
.ticket_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    color: var(--RED-F21137);
    font-size: 2rem;
  }

  .el-image {
    width: 30rem;
    height: 30rem;
  }

  em {
    color: var(--RED-F21137);
    font-size: 2rem;
  }

  .cell_p {
    text-align: center;
    margin: 0;
  }




  .NB_dialog{
    /deep/.el-dialog__body{
        background:linear-gradient(var(--RED-C11C20), 3%, white, white, white, white) !important;
    }
    /deep/ .header-title{
        background-image: linear-gradient(var(--RED-C11C20), var(--RED-79031D), var(--RED-68020F)) !important;
    }
}

.SH_dialog{
    /deep/.el-dialog__body{
        background:linear-gradient(var(--BLUE-006699), 3%, white, white, white, white) !important;
    }
    /deep/ .header-title{
        background-image: linear-gradient(var(--BLUE-3366FF), var(--BLUE-5493f4), var(--BLUE-006699)) !important;
    }
}

.QD_dialog{
    /deep/.el-dialog__body{
        background:linear-gradient(var(--GREEN-003300), 3%, white, white, white, white) !important;
    }
    /deep/ .header-title{
        background-image: linear-gradient(var(--GREEN-006600), var(--GREEN-336600), var(--GREEN-003300)) !important;
    }
}

.TJ_dialog{
    /deep/.el-dialog__body{
        background:linear-gradient(var(--YELLOW-FF9900), 3%, white, white, white, white) !important;
    }
    /deep/ .header-title{
        background-image: linear-gradient(var(--YELLOW-FF9900), var(--YELLOW-CC9966), var(--YELLOW-CC9933)) !important;
    }
}

.XM_dialog{
    /deep/.el-dialog__body{
        background:linear-gradient(var(--PURPLE-330099), 3%, white, white, white, white) !important;
    }
    /deep/ .header-title{
        background-image: linear-gradient(var(--PURPLE-330099), var(--PURPLE-330066), var(--PURPLE-000066)) !important;
    }
}
</style>
