import { Notification } from "element-ui";

const typeTitleEnum = {
    success: "成功",
    warning: "警告",
    error: "错误",
    info: "信息",
};

const message = function (options) {
    // 处理直接调用的情况
    if (typeof options === "string") {
        return message.error(options);
    }
    return message.notify(options);
};

message.notify = function (options) {
    const { title, message, showTitle, ...restOptions } = options;
    let newOptions = {
        ...restOptions,
        title: options.title || "",
    };
    if (!options.title && showTitle) {
        newOptions.title = message;
    } else {
        newOptions.message = message;
    }
    Notification(newOptions);
};

message.success = function (message) {
    const title = typeTitleEnum["success"];
    if (typeof message === "string") {
        this.notify({ type: "success", title, message });
    } else {
        this.notify({
            type: "success",
            title,
            ...message,
        });
    }
};

message.warning = function (message) {
    const title = typeTitleEnum["warning"];
    if (typeof message === "string") {
        this.notify({ type: "warning", title, message });
    } else {
        this.notify({
            type: "warning",
            title,
            ...message,
        });
    }
};

message.error = function (message) {
    const title = typeTitleEnum["error"];
    if (typeof message === "string") {
        this.notify({ type: "error", title, message });
    } else {
        this.notify({
            type: "error",
            title,
            ...message,
        });
    }
};

message.info = function (message) {
    const title = typeTitleEnum["info"];
    if (typeof message === "string") {
        this.notify({ type: "info", title, message });
    } else {
        this.notify({
            type: "info",
            title,
            ...message,
        });
    }
};

// 导出 message 对象
export default message;
