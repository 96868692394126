<!-- 布局 -->
<template>
    <div id="app">
        <el-container direction="vertical">
            <Header />
            <app-main />
            <el-footer v-if="showFooter">
                <!-- <a
                    href="https://www.beian.gov.cn/portal/registerSystemInfo?spm=a21bo.jianhua.1997523009.39.5af92a89VpB9nV&recordcode=33021202002094"
                    class="footer-line"
                    style="margin-right: 8px"
                >
                    <span class="footer-info">浙公网安备 33021202002094号</span>
                </a>
                <a href="https://beian.miit.gov.cn/" class="footer-line">
                    <span class="footer-info">浙ICP备2022012639号-1</span>
                </a>
                <span class="footer-info" style="margin-left: 24px;">版本信息：v{{ version }}</span> -->
                <MyFooter />
            </el-footer>
            <fix-tool-bar />
          </el-container>
    </div>
</template>
<script>
import { version } from '../../../package.json';
import Header from "./components/Header";
import AppMain from "./components/AppMain";
import FixToolBar from "../common/FixToolBar.vue";
import MyFooter from "./components/Footer.vue";
export default {
    data() {
    return {
          version
        };
    },
    computed: {
        showFooter() {
            return !this.$route.path.includes('truckSubscribe') && !this.$route.path.includes('sh_manifest') && !this.$route.path.includes('VGM');
        }
    },
    mounted() {
        if (!this.$store.state.other) {
            this.$store.commit("other/editSystemKey", "NB");
        }
    },
    components: {
        Header,
        AppMain,
        FixToolBar,
        MyFooter,
    },
    methods: {},
};
</script>
<style scoped lang="stylus">

.el-dropdown {
  height: 4rem;
}

.menu_div {
  display: flex;
  margin-right: 5rem;
}

.user_div {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-right: 5rem;
  width: fit-content;
  padding: 0 1rem;

  p {
    // color:#fff !important;
    margin-left: 1rem;
  }
}

.user_div:hover {
  background-color: var(--RED-C11C20);

  p {
    color: #fff;
  }
}

.user_div-f {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: fit-content;
}

.icon_user {
  width: 42px;
  height: 42px;
  object-fit: cover;
  border-radius: 50%;
  background-color: #f2f2f2;
  flex-shrink: 0;
}

.username {
  width: 100px;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.opt_dialog {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: fit-content;
  padding: 0 2rem;
  z-index: 10;
}
.view {
  display: none;
  position:absolute;
  bottom:0;
  left: 50%;
  width:140px;
  height: 140px;
  background: rgb(26,33,45);
  transform: translate(-50%,100%);
}
.view img {
  width: 140px;
  height: 140px;
}


// footer的css
.el-footer {
    height: 100%;
    padding: 0;
}

.footer-line {
    text-decoration: none;
}

.footer-info {
    margin: 0px 0px 0px 20px;
    color: #939393;
}
</style>
