import Vue from "vue";
const state = {
    tableList: [], //物流跟踪订阅页面表格数据列设置
    tableListSH: [],
    tableListQD: [],
    tableListTJ: [],
    tableListXM: [],
    tableListSZ: [],
    shiptableList: [], //船期订阅页列设置
    shiptableListSH: [],
    shiptableListQD: [],
    shiptableListTJ: [],
    shiptableListXM: [],
    shiptableListSZ: [],
    tagNavList: [],
    queryList: [], // 物流实时查询箱货跟踪列设置
    queryList2: [], // 物流实时查询船期列设置
    queryList3: [], // 物流实时查询预配舱单列设置
    historyList: [], // 历史记录列设置
    historyListSH: [], // 历史记录列设置
    historyListQD: [], // 历史记录列设置
    manifestList: [], //宁波舱单页
    userColumnList: [], //用户自定义列设置
    tableListDesignated: [], // 指定箱列设置
    sortMethods: "", //仓单页面排序状态
    pageSize: 10,
    pageSize2: 15,
    liststate: 0,
    showTabber: true,
    showTabbers: false,
};

const getColumnList = (payload = []) => {
    // 如果是对象的话直接返回
    if (Object.prototype.toString.call(payload) === "[object Object]") {
        return [payload];
    }
    let list = [];
    payload.forEach((element) => {
        let data = {
            ...element,
            fixed: Boolean(element.fixed) || false,
        };
        list.push(data);
    });
    return list;
};

const mutations = {
    editTableListNB(state, payload) {
        state.tableList = payload;
    },
    editTableListSH(state, payload) {
        state.tableListSH = payload;
    },
    editTableListQD(state, payload) {
        state.tableListQD = payload;
    },
    editTableListTJ(state, payload) {
        state.tableListTJ = payload;
    },
    editTableListXM(state, payload) {
        state.tableListXM = payload;
    },
    editTableListSZ(state, payload) {
        state.tableListSZ = payload;
    },
    editShipTableListNB(state, payload) {
        state.shiptableList = payload;
    },
    editShipTableListSH(state, payload) {
        state.shiptableListSH = payload;
    },
    editShipTableListQD(state, payload) {
        state.shiptableListQD = payload;
    },
    editShipTableListTJ(state, payload) {
        state.shiptableListTJ = payload;
    },
    editShipTableListXM(state, payload) {
        state.shiptableListXM = payload;
    },
    editShipTableListSZ(state, payload) {
        state.shiptableListSZ = payload;
    },
    editShowTabber(state, payload) {
        state.showTabber = payload;
    },
    editShowTabbers(state, payload) {
        state.showTabbers = payload;
    },
    editListState(state, payload) {
        state.liststate = payload;
    },
    editPageSize(state, payload) {
        Vue.set(state, "pageSize", payload);
    },
    editPageSize2(state, payload) {
        state.pageSize2 = payload;
    },
    editsortMethods(state, payload) {
        state.sortMethods = payload;
    },
    editManifestList(state, payload) {
        state.manifestList = payload;
    },
    editHistoryList(state, payload) {
        state.historyList = getColumnList(payload);
    },
    editHistoryListSH(state, payload) {
        state.historyListSH = getColumnList(payload);
    },
    editHistoryListQD(state, payload) {
        state.historyListQD = getColumnList(payload);
    },
    editTableListDesignated(state, payload) {
        state.tableListDesignated = payload;
    },
    resetManifestListTableList(state, payload) {
        state.manifestList = getColumnList(payload);
    },
    resetTableListNB(state, payload) {
        state.tableList = getColumnList(payload);
    },
    resetTableListSH(state, payload) {
        state.tableListSH = getColumnList(payload);
    },
    resetTableListQD(state, payload) {
        state.tableListQD = getColumnList(payload);
    },
    resetTableListTJ(state, payload) {
        state.tableListTJ = getColumnList(payload);
    },
    resetTableListXM(state, payload) {
        state.tableListXM = getColumnList(payload);
    },
    resetTableListSZ(state, payload) {
        state.tableListSZ = getColumnList(payload);
    },
    resetShipTableListNB(state, payload) {
        state.shiptableList = getColumnList(payload);
    },
    resetShipTableListSH(state, payload) {
        state.shiptableListSH = getColumnList(payload);
    },
    resetShipTableListQD(state, payload) {
        state.shiptableListQD = getColumnList(payload);
    },
    resetShipTableListTJ(state, payload) {
        state.shiptableListTJ = getColumnList(payload);
    },
    resetShipTableListXM(state, payload) {
        state.shiptableListXM = getColumnList(payload);
    },
    resetShipTableListSZ(state, payload) {
        state.shiptableListSZ = getColumnList(payload);
    },
    resetshowTabber(state, payload) {
        state.showTabber = payload;
    },
    resetshowTabbers(state, payload) {
        state.showTabbers = payload;
    },
    queryTableList(state, payload) {
        state.queryList = getColumnList(payload);
    },
    queryTableList2(state, payload) {
        state.queryList2 = getColumnList(payload);
    },
    queryTableList3(state, payload) {
        state.queryList3 = getColumnList(payload);
    },
    resetHistoryList(state, payload) {
        state.historyList = getColumnList(payload);
    },
    resetHistoryListSH(state, payload) {
        state.historyListSH = getColumnList(payload);
    },
    resetHistoryListQD(state, payload) {
        state.historyListQD = getColumnList(payload);
    },
};

const actions = {
    editTableList({ commit }, state) {
        commit("editTableList", state);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
