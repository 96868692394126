<!-- 页面主体 -->
<template>
    <el-main :class="mainClass">
        <transition name="fade-transform" mode="out-in">
            <!-- <keep-alive :include="cachedViews"> -->
            <router-view :key="key"></router-view>
            <!-- </keep-alive> -->
        </transition>
    </el-main>
</template>

<script>
export default {
    computed: {
        // 组件中导出name需要和route中的name相同
        cachedViews() {
            // 缓存页面
            return ["BoxQuery"];
            // return this.$store.state.permission.menuRoutes.map(
            //     (item) => item.name === "BoxQuery",
            // );
        },
        mainClass() {
            const isShanghaiManifest = this.$route.path.includes('sh_manifest') // 上海舱单
            const isVgm = this.$route.path.includes('VGM') // VGM
            const isTruck = this.$route.path.includes('truckSubscribe') // 集卡定位
            let result = 'normal-main';
            if(isShanghaiManifest) {
                result = 'shanghai-manifest-main'
            } else if(isVgm) {
                result = 'vgm-main'
            } else if(isTruck) {
                result = 'truck-main'
            }
            return result;
        },
        key() {
            return this.$route.fullPath;
        },
    },
};
</script>

<style lang="stylus">
.shanghai-manifest-main, .vgm-main, .truck-main {
    height: 100vh;
    overflow: auto;
}

</style>
