<template>
    <NotesDialog
        width="750px"
        :visible.sync="visibleSync"
        top="5vh"
        @know="handleKnow"
    >
        <div class="notice">
            <div class="center"><h1>版本更新</h1></div>
            <div class="notice-content">
                <div class="line">致各位放舱网的用户：</div>
                <div class="line">为了给您提供更优质的服务，放舱网于 2025 年 02 月 25 日进行了更新。</div>
                <div class="line bold">更新内容：</div>
                <div class="line">- 新增海外运踪</div>
                <div class="line">- 调整船舶定位和集卡定位到操作栏</div>
                <div class="line">- 换船标记增加单独列</div>
                <div class="line">- 移除列表单独删除按钮，统一使用头部删除按钮</div>
                <div class="line bold">功能页面截图：</div>
                <div><img src="./imgs/notes-6/3.jpg" width="700"/></div>
                <div><img src="./imgs/notes-6/1.jpg" width="700" /></div>
                <div><img src="./imgs/notes-6/2.jpg" width="700" /></div>

            </div>
        </div>
    </NotesDialog>
</template>

<script lang="ts">
import NotesDialog from './NotesDialog.vue';

export default {
    components: {
        NotesDialog,
    },
    data() { 
        return {
            visibleSync: false
        }
    },
    methods: {
        open() { 
            this.visibleSync = true
        },
        handleKnow() { 
            this.visibleSync = false;
            this.$emit('know')
        }
    }
}
</script>