<template>
    <NotesDialog
        width="750px"
        :visible.sync="visibleSync"
        top="5vh"
        @know="handleKnow"
    >
        <div class="notice">
            <div class="center"><h1>版本更新</h1></div>
            <div class="notice-content">
                <div class="line">致各位放舱网的用户：</div>
                <div class="line">为了给您提供更优质的服务，放舱网于 2025 年 02 月 26 日进行了更新。</div>
                <div class="line bold">更新内容：</div>
                <div class="line"> 临时下线海外运踪功能 </div>
                <div class="line bold" style="margin-top: 20px;color:#F56C6C"> 我们将在优化完成后尽快恢复此功能(已订阅的数据会保留，您无需担心数据丢失问题)，感谢您的理解与支持！ </div>
                <div class="replenish-info" style="margin-top: 20px;">
                    <div class="content">
                        <span>下线时间：2025 年 02 月 26 日</span>
                        <span>下线范围：海外运踪功能</span>
                    </div>
                </div>
            </div>
        </div>
    </NotesDialog>
</template>

<script lang="ts">
import NotesDialog from './NotesDialog.vue';

export default {
    components: {
        NotesDialog,
    },
    data() { 
        return {
            visibleSync: false
        }
    },
    methods: {
        open() { 
            this.visibleSync = true
        },
        handleKnow() { 
            this.visibleSync = false;
            this.$emit('know')
        }
    }
}
</script>