<!-- 开屏广告 -->
<template>
    <div>
        <el-dialog
            :footer="null"
            :close-on-press-escape="false"
            append-to-body
            :title="null"
            :show-close="false"
            :visible="visible"
            :close-on-click-modal="false"
            class="open-ads"
            width="65vw"
            center            
            :custom-class="dialogCustomClass"
        >
            <div class="wrapper">
                <div class="img-wrapper">
                    <div class="close-btn">
                        金牌供应商
                        <span class="small-font">
                            {{ time ? time + "s" : "" }}
                        </span>
                    </div>
                    <el-image
                        :src="showAdsInfo.url"
                        alt=""
                        class="img"
                        @click="handleDetail"
                        @load="handleImgLoad"
                        @error="handleImgLoadError"
                    />
                </div>
            </div>
        </el-dialog>
        <el-drawer
            title="详情"
            :visible.sync="pdfVisible"
            :withHeader="false"
            size="50%"
            append-to-body
        >
            <div class="bar">
                <DialogFooter
                    @confirm="handlePre()"
                    @cancel="handleNext()"
                    showTab="NB"
                    :confirmOptions="{
                        size: 'small',
                    }"
                    :cancelOptions="{
                        size: 'small',
                    }"
                    cancelText="下一个"
                    style="gap: 10px; justify-content: flex-start"
                    confirmText="上一个"
                    top="0"
                ></DialogFooter>
                <DialogFooter
                    @confirm="goToDetail()"
                    @cancel="handleDetailClose"
                    showTab="NB"
                    :confirmOptions="{
                        size: 'small',
                    }"
                    :cancelOptions="{
                        size: 'small',
                    }"
                    cancelText="关闭"
                    style="gap: 10px; justify-content: flex-end"
                    confirmText="网页显示"
                    top="0"
                ></DialogFooter>
            </div>
            <PdfView
                :url="adsList[curShowIndex].pdfUrl"
                style="height: calc(100% - 50px)"
            />
        </el-drawer>
        <div
            class="small-window"
            v-if="!visible && !pdfVisible && smallVisible"
        >
            <div class="small-window-container">
                <div class="title">
                    金牌供应商
                    <span class="close-btn" @click="handleCloseSmallWindow">
                        X
                    </span>
                </div>
                <img
                    :src="showAdsInfo.url"
                    alt=""
                    class="img"
                    @click="handleDetail"
                />
            </div>
        </div>
    </div>
</template>
<script>
// 打开详情的key
const OPEN_KEY = `open_ads`;
import PdfView from "../common/PdfView.vue";
import DialogFooter from "@/components/layout/DialogFooter.vue";
import { adClick, adClose } from "@/api/common";
import { apiCallback } from "@/utils";
import bus from "@/utils/bus.js";

export default {
    name: "OpenAds",
    components: {
        PdfView,
        DialogFooter,
    },
    computed: {
        dialogCustomClass() {
            let result = 'custom-open-ads';
            if(this.showAdsInfo.direction === 'vertical'){
                result += ' vertical';
            }else{
                result += ' horizontal';
            }
            return result;
        }
    },
    data() {
        return {
            visible: false,
            adsList: [
            {
                    title: "指定箱业务",
                    key: 'zhidingxiang',
                    url: `https://web.nbhuojian.com/static/fangcangwang/ads/img/zhidingxiang.png?t_=${new Date().getTime()}`,
                    pdfUrl: `https://web.nbhuojian.com/static/fangcangwang/ads/pdf/%E6%8C%87%E5%AE%9A%E7%AE%B1.pdf?t_=${new Date().getTime()}`,
                    direction: 'horizontal',
                    hidden: true, // 是否隐藏(只展示详情)
                },
                {
                    title: "综保区业务",
                    key: 'zongbaoqu',
                    url: `https://web.nbhuojian.com/static/fangcangwang/ads/img/zongbaoqu.jpg?t_=${new Date().getTime()}`,
                    direction: 'vertical',
                    pdfUrl: `https://web.nbhuojian.com/static/fangcangwang/ads/pdf/%E7%BB%BC%E4%BF%9D%E5%8C%BA.pdf?t_=${new Date().getTime()}`,
                },
                // {
                //     title: "上海隽硕国际货物运输代理有限公司",
                //     key: 'junshuo',
                //     url: `https://web.nbhuojian.com/static/fangcangwang/ads/img/junshuo.png?t_=${new Date().getTime()}`,
                //     pdfUrl: `https://web.nbhuojian.com/static/fangcangwang/ads/pdf/%E9%9A%BD%E7%A1%95.pdf?t_=${new Date().getTime()}`,
                // },
                // {
                //     title: "义乌市文鳐报关代理有限公司",
                //     key: 'wenyao',
                //     url: `https://web.nbhuojian.com/static/fangcangwang/ads/img/wenyao.png?t_=${new Date().getTime()}`,
                //     pdfUrl: `https://web.nbhuojian.com/static/fangcangwang/ads/pdf/%E6%96%87%E9%B3%90.pdf?t_=${new Date().getTime()}`,
                // },
            ],
            showAdsInfo: {
                url: "",
                pdfUrl: "",
                title: "",
            },
            time: 3,
            pdfVisible: false,
            smallVisible: false,
            curShowIndex: 0,
            showDetailAdId: "", // 点击显示广告详情后，记录的广告id
            showDetailStartTime: 0, // 点击显示广告详情，记录的时间
        };
    },
    created() {
        this.calNeedShowAds();
        bus.$on("open-zdx-detail", () => {
            const cacheAdIndex = this.adsList.findIndex(item => item.key === "zhidingxiang");
            this.curShowIndex = cacheAdIndex;
            this.showAdsInfo = this.adsList[cacheAdIndex];
            this.$nextTick(() => {
                this.handleDetail();
            })
        })
        bus.$on("router-change", (data) => {
            const showSmallWindow = ["/logistics", "/shipSubscrib","/boxquery","/manifestQuery","/classAnalysis"]
            if(showSmallWindow.includes(data.to.path)){
                this.smallVisible = true;
            }else{
                this.smallVisible = false;
            }
        })
    },
    methods: {
        handleImgLoad() {
            // 计算需要显示的广告
            this.time = 1;
            this.timer = setInterval(() => {
                this.time--;
                if (this.time === 0) {
                    clearInterval(this.timer);
                    this.visible = false;
                    if(this.showAdsInfo.key === "zhidingxiang"){
                        bus.$emit("open-zdx-btn");
                    }
                }
            }, 2000);
        },
        handleImgLoadError() { 
            this.handleImgLoad()
            this.smallVisible = false;
        },
        open() {
            // 测试环境不展示广告
            if(process.env.NODE_ENV === "production"){
                // 计算需要显示的广告
                this.visible = true;
            }
            this.smallVisible = true;
        },
        // 格式化数据(默认值)
        formatData() {
            this.showAdsInfo = this.adsList[0];
            this.curShowIndex = 0;
            this.smallVisible = true;
        },
        // 计算需要显示的广告
        calNeedShowAds() {
            const lastShowAdKey = localStorage.getItem("fcw_lastShowAdKey");
            const cacheAdList = this.adsList.filter(item => !item.hidden);
            // 获取当前查看的次数
            if (!lastShowAdKey) {
                localStorage.setItem("fcw_lastShowAdKey", cacheAdList[0].key);
                this.showAdsInfo = cacheAdList[0];
                this.curShowIndex = 0;
                return;
            } else { 
                const lastShowAdIndex = cacheAdList.findIndex(item => item.key === lastShowAdKey);
                if (lastShowAdIndex === -1) {
                    localStorage.setItem("fcw_lastShowAdKey", cacheAdList[0].key);
                    this.showAdsInfo = cacheAdList[0];
                    this.curShowIndex = 0;
                    return;
                } else {
                    const newIndex = lastShowAdIndex + 1;
                    if (newIndex < cacheAdList.length) {
                        this.curShowIndex = newIndex;
                        this.showAdsInfo = cacheAdList[newIndex];
                        localStorage.setItem("fcw_lastShowAdKey", cacheAdList[newIndex].key);
                    } else {
                        localStorage.setItem("fcw_lastShowAdKey", cacheAdList[0].key);
                        this.showAdsInfo = cacheAdList[0];
                        this.curShowIndex = 0;
                    }
                }
            }
        },
        handleCloseSmallWindow() {
            this.smallVisible = false;
        },
        handleClose() {
            if (this.time) return;
            this.visible = false;
        },
        // 广告点击
        handleAdClick() {
            this.showDetailAdId = "";
            this.showDetailStartTime = new Date().getTime();;
            if(this.$store.state.user.token){
                adClick({
                    adName: this.showAdsInfo.title,
                }).then(res => {
                    const data = apiCallback(res);
                    this.showDetailAdId = data;
                });
            }
        },

        handleDetail() {
            clearInterval(this.timer);
            this.visible = false;
            this.pdfVisible = true;
            this.handleAdClick();
        },
        goToDetail() {
            window.open(this.adsList[this.curShowIndex].pdfUrl);
            this.handleAdClose(true);
        },
        // 处理上一个，下一个
        handleNext() {
            if (this.curShowIndex === this.adsList.length - 1) {
                this.curShowIndex = 0;
            } else {
                this.curShowIndex++;
            }
            this.showAdsInfo = this.adsList[this.curShowIndex];
            this.$nextTick(() => {
                this.handleAdClose().then(() => {
                    this.handleAdClick();
                });
            });
        },
        handlePre() {
            if (this.curShowIndex === 0) {
                this.curShowIndex = this.adsList.length - 1;
            } else {
                this.curShowIndex--;
            }
            this.showAdsInfo = this.adsList[this.curShowIndex];
            this.$nextTick(() => {
                this.handleAdClose().then(() => {
                    this.handleAdClick();
                });
            });
        },
        // 广告关闭或切换
        handleAdClose(newPageAd = false) {
            return new Promise((resolve, reject) => { 
                if (this.showDetailAdId) {
                    // 点击的时候记录成功拿到id才执行此处逻辑
                    const endTime = new Date().getTime();
                    const stayTime = endTime - this.showDetailStartTime;
                    adClose({
                        adId: this.showDetailAdId,
                        stayTime: newPageAd ? 0 : stayTime,
                    }).finally(() => { 
                        this.showDetailAdId = "";
                        this.showDetailStartTime = 0;
                        resolve();
                    });
                } else {
                    resolve();
                }
            })
        },
        handleDetailClose() {
            this.handleAdClose();
            this.pdfVisible = false;
        },
    },
};
</script>
<style lang="stylus">
.open-ads .el-dialog__body{
    padding:0;

}

.open-ads .el-dialog__header{
    padding:0
}
</style>

<style lang="stylus">
@keyframes shake {
    10% {
        transform: rotate(15deg);
      }
      20% {
        transform: rotate(-10deg);
      }
      30% {
        transform: rotate(5deg);
      }
      40% {
        transform: rotate(-5deg);
      }
      50%,100% {
        transform: rotate(0deg);
      }
}

.custom-open-ads{
    border-radius:25px;
    overflow: hidden;
    background-color: transparent;
    border: none;
    box-shadow: none;
    .wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        .img-wrapper{
            position: relative;
            .el-image__error{
                min-height: 50vh;
            }
            img{
                // border-radius:25px;
                overflow: hidden;
            }
        }
    }
    &.vertical{
        margin-top: 5vh !important;
        .wrapper{
            .img-wrapper{
                .el-image{
                    height: 90vh;
                    width: auto;
                }
                img{
                    height: 100%;
                    border-radius:4px;
                }
            }
        }
    }
    &.horizontal{
        .wrapper{
            .el-image{
                height: 70vh;
                width: 100%;
            }
            img{
                border-radius:15px;
                width: 100%;
                height:100%;
            }
        }
    }
}

.wrapper{
    position: relative; width: 100%;
    .close-btn{
        position:absolute;
        right:20px;
        top:10px;
        z-index:999;
        color:#b87100;
        padding:0px 8px;
        border:1px solid #b87100;
        font-size:12px;
        text-align:center;
        cursor:pointer;
        .small-font{
            font-size:10px;
        }
    }
}

.bar{
    padding:5px 10px;
    display:flex;
    justify-content:space-between;
}



.small-window{
    position:fixed;
    bottom:40px;
    left:30px;
    background: #fff;s
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    display:flex;
    flex-direction:column;
    justify-content:flex-end;
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    cursor:pointer;
    animation: shake 2.5s .15s linear infinite;
    z-index: 999;
    &:hover{
        animation-play-state: paused;
        transform: rotate(0deg);
    }
    .title{
        padding-left:5px;
        font-size:12px;
        margin-bottom:5px;
        color:#b87100;
        font-weight:bold;
    }
    .close-btn{
        padding-left:3px;
        color:#333;
    }
    .img{
        width:85px;
        max-height:100px;
        overflow:hidden;
    }
}
</style>
