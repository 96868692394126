<!--菜单栏  -->
<template>
    <el-header>
        <div :class="{ header: true }" ref="headerRef">
            <div class="logo-and-menu">
                <el-image :src="icon_logo" @click="goHome()"></el-image>
                <div
                    class="icon-move icon-move-left"
                    v-if="curCanScrollPage && curScrollPage !== 0"
                    @click="handleMenuScroll('left')"
                >
                    <i class="el-icon-arrow-left"></i>
                </div>
                <div class="header_menu_wrapper">
                    <el-menu
                        background-color="#f1f3f6"
                        mode="horizontal"
                        active-text-color="#409EFF"
                        router
                        :default-active="activeMenu"
                        class="page_header_menu"
                        ref="pageHeaderMenu"
                        :style="{ maxWidth: curMenuWidth + 'px' }"
                    >
                        <div
                            :class="{
                                menu_item_wrapper: true,
                                menu_item_wrapper_box_query:
                                    route.name === 'BoxQuery' ||
                                    route.name === 'sh_manifest',
                            }"
                            v-for="route in showMenus"
                        >
                            <MenuTip
                                class="menu_item_tooltip logistics"
                                title="深圳NEW(含南沙)"
                                v-if="route.name === 'Logistics'"
                                color="#409eff"
                            ></MenuTip>
                            <!-- <MenuTip
                                class="menu_item_tooltip shipSubscrib"
                                title="厦门New"
                                v-if="route.name === 'ShipSubscrib'"
                            ></MenuTip> -->
                            <MenuTip
                                class="menu_item_tooltip shipSubscrib"
                                title="厦门NEW"
                                v-if="route.name === 'ShipSubscrib'"
                            ></MenuTip>
                            <MenuTip
                                class="menu_item_tooltip truckSubscribe"
                                title="全国"
                                v-if="route.name === 'TruckSubscribe'"
                                color="#409eff"
                            ></MenuTip>
                            <MenuTip
                                class="menu_item_tooltip boxQuery"
                                title="数据同步EDI"
                                v-if="route.name === 'BoxQuery'"
                            ></MenuTip>
                            <MenuTip
                                class="menu_item_tooltip classAnalysis"
                                title="AI升级"
                                v-if="route.name === 'ClassAnalysis'"
                            ></MenuTip>
                            <MenuTip
                                class="menu_item_tooltip fileAnalysis"
                                title="托单"
                                v-if="route.name === 'FileAnalysis'"
                                :width="26"
                            ></MenuTip>
                            <MenuTip
                                class="menu_item_tooltip sh_manifest"
                                title="舱单发送(30元/票)"
                                v-if="route.name === 'sh_manifest'"
                            ></MenuTip>
                            <MenuTip
                                class="menu_item_tooltip VGM"
                                title="上海港区(5元/柜)"
                                v-if="route.name === 'VGM'"
                            ></MenuTip>
                            <menu-item
                                :key="route.path"
                                :item="route"
                                :base-path="route.path"
                            ></menu-item>
                        </div>
                    </el-menu>
                </div>
            </div>
            <div
                class="icon-move icon-move-right"
                v-if="curScrollPage < curCanScrollPage"
                @click="handleMenuScroll('right')"
            >
                <i class="el-icon-arrow-right"></i>
            </div>

            <div class="user_div-f">
                <!-- <el-link
                    type="danger"
                    id="notice-tips"
                    @click="$refs.noteDialog.open()"
                >
                    公告:温馨提示
                </el-link> -->
                <el-tooltip content="点击我查看历史更新公告">
                    <div class="version-list-btn" @click="$refs.noteDialog.open()">
                        <i class="el-icon-collection"></i>
                        <span>更新公告</span>
                    </div>
                </el-tooltip>

                <el-dropdown
                    v-if="getToken()"
                    size="medium"
                    placement="bottom-end"
                    trigger="hover"
                >
                    <div style="display: flex">
                        <div class="account" @click="handlePushToCharge()">
                            <img
                                src="@/assets/img/coin.png"
                                width="20"
                                height="20"
                                alt=""
                            />
                            <div class="money">
                                {{ Number(currentAccount || 0) / payRate }}
                                <span style="font-size: 14.5px">火币</span>
                            </div>
                        </div>
                        <el-dropdown-menu
                            slot="dropdown"
                            class="header-new-drop"
                        >
                            <el-dropdown-item
                                v-for="item in accountInfoList"
                                :key="item.label"
                            >
                                <div style="display: flex; align-items: center">
                                    <div
                                        style="
                                            display: flex;
                                            justify-content: flex-end;
                                            width: 100px;
                                        "
                                    >
                                        {{ item.label }}：
                                    </div>
                                    <img
                                        src="@/assets/img/coin.png"
                                        width="20"
                                        height="20"
                                        alt=""
                                    />
                                    {{ Number(item.content || 0) / payRate }}
                                </div>
                            </el-dropdown-item>
                            <div class="invite-notice-entry">
                                <span>火币不够用?</span>
                                <el-button
                                    type="text"
                                    size="mini"
                                    @click="handleShowNoteInvite"
                                >
                                    获取攻略
                                </el-button>
                            </div>
                        </el-dropdown-menu>
                    </div>
                </el-dropdown>
                <DropDownMenu></DropDownMenu>
            </div>
        </div>
        <login-dialog
            v-if="loginDialogVisible"
            ref="LoginDialog"
        ></login-dialog>
        <TimeLineNoteDialog v-if="!loginDialogVisible" ref="noteDialog" />
        <note-dialog v-if="!loginDialogVisible"></note-dialog>
        <note-invite-dialog
            v-if="!loginDialogVisible"
            ref="noteInviteDialog"
        ></note-invite-dialog>
        <OpenAds ref="openAdsRef" v-if="!loginDialogVisible" />
    </el-header>
</template>

<script>
import MenuItem from "./MenuItem.vue";
import { getToken } from "@/utils/auth";
import LoginDialog from "@/components/register/WechatLoginDialog";
import { getUserAccountInfo } from "@/utils";
import DropDownMenu from "./DropDownMenu.vue";
import LogoutDialog from "@/components/register/LogoutDialog";
import SwitchIdDialog from "@/components/register/SwitchIdDialog";
import MenuTip from "@/components/common/MenuTip.vue";
import { mapGetters } from "vuex";
import bus from "@/utils/bus.js";
import AppLink from "./Link";
import { payRate } from "@/utils/pay-data-list";
import NoteDialog from "@/components/update-notes/notes-2.vue";
import TimeLineNoteDialog from "@/components/update-notes/timeline-note.vue";
import NoteInviteDialog from "@/components/update-notes/notes-invite.vue";
import OpenAds from "@/components/open-ads/index.vue";
import { NOT_INIT_ADS } from "@/components/open-ads/constants";
export default {
    components: {
        MenuItem,
        AppLink,
        LoginDialog,
        DropDownMenu,
        LogoutDialog,
        SwitchIdDialog,
        MenuTip,
        NoteDialog,
        NoteInviteDialog,
        TimeLineNoteDialog,
        OpenAds,
    },
    mounted() {
        this.init();
        window.addEventListener("beforeunload", (e) =>
            this.beforeunloadHandler(e),
        );
        window.addEventListener("unload", (e) => this.unloadHandler(e));
    },
    destroyed() {
        window.removeEventListener("beforeunload", (e) =>
            this.unloadHandler(e),
        );
        window.removeEventListener("unload", (e) => this.unloadHandler(e));
    },
    data() {
        return {
            getToken: getToken,
            icon_logo: require("@/assets/img/logo.png"),
            BoxQueryMenuTooltip: require("@/assets/icon/box_query_menu_tooltip.svg"),
            ShipSubscribMenuTooltip: require("@/assets/icon/shipSubscrib_menu_tooltip.svg"),
            AnalysisMenuTooltip: require("@/assets/icon/analysis_menu_tooltip.svg"),
            AnalysisMenu: require("@/assets/icon/analysis_menu.svg"),
            OptuserInfo: [
                {
                    label: "用户中心",
                    command: "user_center",
                },
                {
                    label: "企业认证",
                    command: "company_certify",
                },
                {
                    label: "切换身份",
                    command: "switch_role",
                },
                {
                    label: "退出",
                    command: "quit",
                },
            ],
            loginDialogVisible: false,
            logoutDialogVisible: false,
            switchIdDialogVisible: false,
            payRate,
            // 当前屏幕尺寸
            curWindowWidth: window.innerWidth,
            // 当前menu应该有的宽度
            curMenuWidth: 0,
            // 真实的菜单栏宽度
            trulyMenuWidth: 0,
            // 当前菜单滚动到第几页了
            curScrollPage: 0,
        };
    },
    computed: {
        ...mapGetters([
            "menu_routes",
            "token",
            "loginId",
            "uAccount",
            "cAccount",
            "accountUser",
            "accountCompany",
        ]),
        activeMenu() {
            const route = this.$route;
            const { meta, path } = route;
            if (meta.activeMenu) {
                return meta.activeMenu;
            }
            return path;
        },
        showMenus() {
            return this.menu_routes.filter((item) => !item.meta?.hidden);
        },
        currentAccount() {
            return !this.loginId || this.loginId === "personal"
                ? this.uAccount
                : this.cAccount;
        },
        currentAccountInfo() {
            return !this.loginId || this.loginId === "personal"
                ? this.accountUser
                : this.accountCompany;
        },
        //  当前能够滚动的页数
        curCanScrollPage() {
            if (this.curMenuWidth) {
                // 当前藏的部分
                const hideWidth = this.trulyMenuWidth - this.curMenuWidth;
                // 允许一点误差
                if (hideWidth < 20) {
                    return 0;
                }
                // 藏的部分除以显示部分的倍数
                return Math.ceil(hideWidth / this.curMenuWidth);
            }
            return 0;
        },
        accountInfoList() {
            return [
                {
                    label: `账户余额`,
                    content: this.currentAccount || 0,
                },
                {
                    label: `上海免费余额`,
                    content: this.currentAccountInfo?.freeBalance || 0,
                },
                {
                    label: `宁波免费余额`,
                    content: this.currentAccountInfo?.nbFreeBalance || 0,
                },
            ];
        },
    },
    watch: {
        activeMenu(val) {
            if (val.indexOf("fileAnalysis") !== -1) {
                bus.$emit("goFileAnalysisPage");
            }
            this.handleAdsShow();
        },
    },
    methods: {
        handleAdsShow() {
            // 拿store中的值
            const isAdsLook = this.$store.state.other.isLookAds;
            if(isAdsLook){
                return;
            }
            const fullRouterPath = this.$router.currentRoute.fullPath;
            if (
                this.$refs.openAdsRef &&
                ["/logistics", "/shipSubscrib","/boxquery","/manifestQuery","/classAnalysis"].includes(fullRouterPath)
            ) {
                this.$refs.openAdsRef.open();
            }
            this.$store.commit("other/setLookAds", true);
        },
        handlePushToCharge() {
            this.$router.currentRoute.fullPath != "/pay/charge" &&
                this.$router.push("/pay/charge");
        },
        handleShowNoteInvite() {
            this.$refs.noteInviteDialog.open();
        },
        goHome() {
            if (this.$router.currentRoute.fullPath != "/home") {
                this.$router.push("/");
            } else {
                this.$router.go(0);
            }
        },
        initMenu() {
            // 初始化菜单栏
            this.curMenuWidth = 0;
            // 当前滚动到第几页
            this.curScrollPage = 0;
            const menuDom = this.$refs.pageHeaderMenu.$el;
            // 重置滚动举例
            menuDom.style.transform = `translateX(0px)`;
            this.curWindowWidth = this.$refs.headerRef.offsetWidth;
            this.$nextTick(() => {
                // 图片有个margin-left:20px
                // 然后logo图片的宽度 el-image， document.querySelector('.logo-and-menu .el-image').offsetWidth
                // 右侧有个user_div-f的宽度， document.querySelector('.user_div-f').offsetWidth
                // 再预留一点宽度 100
                const preWidth = 100;
                // 需要减去的宽度
                const needWidth = 20 + preWidth;
                const menuWidth =
                    this.curWindowWidth -
                    needWidth -
                    document.querySelector(".logo-and-menu .el-image")
                        .offsetWidth -
                    document.querySelector(".user_div-f").offsetWidth;
                this.curMenuWidth = menuWidth;
                menuDom.style.maxWidth = `${menuWidth}px`;
            });
        },
        calTrulyMenuWidth() {
            this.trulyMenuWidth = 0;
            this.$nextTick(() => {
                const domArr = document.querySelectorAll(
                    ".page_header_menu .menu_item_wrapper",
                );
                domArr.forEach((item) => {
                    this.trulyMenuWidth += item.offsetWidth;
                });
            });
        },
        init() {
            // 初始化menu信息
            this.initMenu();
            // 计算真实的menu-tab需要的宽度
            this.calTrulyMenuWidth();
            // 检测窗口变化
            window.addEventListener("resize", this.initMenu);
            this.$store.commit("other/setLookAds", false);
            if (!getToken()) {
                this.login();
            } else {
                const fullRouterPath = this.$router.currentRoute.fullPath;
                // 避免重复请求取消导致不生效
                if (fullRouterPath == "/pay/charge") {
                    return;
                }
                // 请求余额数据
                getUserAccountInfo();
                this.handleAdsShow();
            }
        },
        beforeunloadHandler(e) {
            this._beforeUnload_time = new Date().getTime();
        },
        unloadHandler(e) {
            this._gap_time = new Date().getTime() - this._beforeUnload_time;
            //判断是窗口关闭还是刷新
            if (
                this.$router.currentRoute.fullPath == "/logistics" ||
                this.$router.currentRoute.fullPath == "/boxquery" ||
                this.$router.currentRoute.fullPath == "/history" ||
                this.$router.currentRoute.fullPath == "/shipSubscrib" ||
                this.$router.currentRoute.fullPath == "/manifestSend" ||
                this.$router.currentRoute.fullPath == "/truckSubscribe" ||
                this.$router.currentRoute.fullPath.includes("/user") ||
                this.$router.currentRoute.fullPath.includes("/pay")
            ) {
                if (this._gap_time <= 4) {
                    //如果是登录状态，关闭窗口前，移除用户
                    this.$nextTick(() => {
                        this.$store.commit("user/editToken", "");
                        this.$store.commit("user/editUserid", "");
                        this.$store.commit("user/editLoginId", "personal");
                    });
                }
                this.$nextTick(() => {
                    this.$store.commit("ship/editBlonBoxInfo", []);
                    this.$store.commit("ship/editShipInfo", []);
                    this.$store.commit("ship/editDockInfo", []);
                    this.$store.commit("ship/editAllBoxInfo", []);
                    this.$store.commit("ship/editBoxStatus", []);
                    this.$store.commit("ship/editShipStatus", []);
                });
            }
        },
        //登录，显示二维码弹窗
        login() {
            this.loginDialogVisible = true;
            this.$nextTick(() => {
                this.$refs.LoginDialog.init();
            });
        },
        // 控制菜单栏滚动
        handleMenuScroll(type) {
            // 每一次移动的距离(实际menu的长度-当前menu的长度)/当前能够滚动的页数
            const eachMove =
                (this.trulyMenuWidth - this.curMenuWidth) /
                this.curCanScrollPage;
            const menuDom = this.$refs.pageHeaderMenu.$el;
            if (type === "left") {
                this.curScrollPage--;
            } else if (type === "right") {
                this.curScrollPage++;
            }
            menuDom.style.transform = `translateX(${
                -this.curScrollPage * eachMove
            }px)`;
        },
    },
};
</script>

<style scoped lang="stylus">
.header-new-drop{
    padding:10px  0 !important;
    background-image:url('../../../assets/img/menu/cirque.png');
    background: linear-gradient(180deg, #FFDFDF 0%, #FFFFFF 24%, #FFFFFF 100%);
}

.account{
    flex-wrap:nowrap;
    display: flex;
    align-items: center;
    cursor: pointer;
    white-space:nowrap;
    padding:0 5px;

}
.money{
    display: flex;
    align-items: center;
    margin-left: 5px;
    line-height: 20px;
    height: 20px;
    font-size:16px;
    font-weight:bold;
    color:#444;

}
.header{
    display:flex;
    width:100%;
    height:100%;
    justify-content: space-between;
}
.header_menu_wrapper{
    height:100%;
    display:flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden;
}
.el-header {
  background-color: #f1f3f6;
  color: #333;
  box-shadow: 10px 10px 18px rgba(55, 84, 170, 0.15);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 5;
  position: fixed;
  width: 100%;
  top: 0;
}

@media (max-width: 1450px) {
  .logo-and-menu{
    margin: 0 8px !important;
  }

  .el-header{
    padding-left: 0;
  }
}



.el-menu {
  &.page_header_menu{
    display: flex;
    border-bottom: none;
    margin-left: 20px;
    transition: transform 1s ease-in-out;
    .el-menu--horizontal {
      border-bottom: 0px;
    }
    .menu_item_wrapper{
      position:relative;
    }
    .menu_item_tooltip{
        position:absolute;
        bottom:-8px;
        transform:translateY(-115%);
        z-index:999;
        &.boxQuery {
            left: 40%;
        }
        &.truckSubscribe{
            left: 60%;
        }
        &.fileAnalysis{
            left : 70%;
        }
        &.logistics {
            left: 38%;
        }
        &.shipSubscrib{
            left: 55%;
        }
        &.classAnalysis{
            left:55%;
        }
        &.sh_manifest{
            left:20%;
        }
        &.VGM{
          left:25%;
        }
    }
  }
}
.logo-and-menu {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  width: fit-content;
  margin-left: 20px;
  padding-bottom: 4px;
  .menu_item_wrapper .el-image{
    width: 90px;
    height: 28px;
    object-fit: cover;
  }

  .el-image {
    object-fit: cover;
    height: 35px ;
    width: 78px ;
    padding-bottom: 4px;
  }
}

.user_div-f {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: flex-end;
  margin-left:8px;
}

.icon-move{
   width:20px;
   height: 100%;
   padding-top: 8px;
   box-sizing: border-box;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   cursor:pointer;
   color:#68020f;
   margin: 0 10px;
   &:hover{
    background-color: #d3d3d3;
   }
   i{
    font-size: 20px;
    color:#68020f;
    font-weight: bold;
   }
}
.invite-notice-entry{
    font-size:12px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    span{
        padding: 7px 0;
        margin-right: 8px;
    }
}
.version-list-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #ff551f;
    i{
        font-size: 20px;
    }
    span{
        display: inline-block;
        font-size: 14px;
        font-weight: bold;
        margin-left: 4px;
    }
    margin-right: 10px;
}
</style>
