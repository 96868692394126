<template>
    <NotesDialog
        width="750px"
        :visible.sync="visibleSync"
        top="5vh"
        @know="handleKnow"
    >
        <div class="notice">
            <div class="center"><h1>版本更新</h1></div>
            <div class="notice-content">
                <div class="line">致各位放舱网的用户：</div>
                <div class="line">为了给您提供更优质的服务，放舱网于 2025 年 03 月 24 日进行了更新。</div>
                <div class="line bold">更新内容：</div>
                <div class="line">- 新增极速跟踪(宁波港)，会极大提升订阅数据更新速度</div>
                <div class="line">- 恢复海外运踪</div>
                <div class="line bold">海外运踪使用说明：</div>
                <div><img src="./imgs/notes-8/1.jpg" width="700" /></div>
                <div class="line">海外运踪详情示例：</div>
                <div><img src="./imgs/notes-8/2.jpg" width="700" /></div>
                <div class="line bold">极速跟踪使用说明：</div>
                <div><img src="./imgs/notes-8/3.jpg" width="700" /></div>
            </div>
        </div>
    </NotesDialog>
</template>

<script lang="ts">
import NotesDialog from './NotesDialog.vue';

export default {
    components: {
        NotesDialog,
    },
    data() { 
        return {
            visibleSync: false
        }
    },
    methods: {
        open() { 
            this.visibleSync = true
        },
        handleKnow() { 
            this.visibleSync = false;
            this.$emit('know')
        }
    }
}
</script>