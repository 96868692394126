<template>
    <div class="footer">
        <!-- <div class="foot_head">
            <div
                v-for="(item, index) in footList"
                :key="index"
                class="head_item"
            >
                <img :src="item.url" style="width: 50px; height: 50px" />
                <div class="item_right">
                    <h2>{{ item.title }}</h2>
                </div>
            </div>
        </div> -->
        <div class="foot_content">
            <div class="footer-left">
                <ul style="list-style: none">
                    <p class="footer-title">
                        关于我们
                    </p>
                    <li
                        @click="enterProDetail('http://www.nbhuojian.com/')"
                    >
                        公司简介
                    </li>
                    <!-- <li
                        @click="enterProDetail('http://www.nbhuojian.com/#/company_state')"
                    >
                        最新动态
                    </li> -->
                </ul>
                <div>
                    <p class="footer-title">联系我们</p>
                    <p class="foot_touch">
                        <el-image
                            :src="phoneIcon"
                            lazy
                            style="width: 18px; height: 18px"
                        ></el-image>
                        <span style="margin-left: 10px">0574-27682901 0574-27836910</span>
                    </p>
                    <p class="foot_touch">
                        <el-image
                            :src="mobileIcon"
                            lazy
                            style="width: 20px; height: 20px"
                        ></el-image>
                        <span style="margin-left: 10px">
                            13429380909 13685859945
                        </span>
                    </p>
                </div>
                <!-- <ul style="list-style: none">
                    <p style="font-size: 14px; margin-bottom: 15px">
                        产品功能
                    </p>
                    <li>物流跟踪及订阅</li>
                    <li>船期订阅</li>
                    <li>物流实时查询</li>
                    <li>箱货跟踪</li>
                </ul> -->
            </div>
            <div class="footer-right">
                <ul style="list-style: none">
                    <p class="footer-title">
                        公司产品
                    </p>
                    <li>F1接单输单系统</li>
                    <li>单证中心</li>
                    <li>放舱网</li>
                </ul>
                <div
                    style="
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    "
                >
                    <p class="footer-title">欢迎关注订阅放舱网</p>
                    <div class="weixin">
                        <el-image
                            :src="qrCodeFirst"
                            lazy
                            class="qr_img"
                        ></el-image>
                        <el-image
                            :src="qrCodeSecond"
                            lazy
                            class="qr_img"
                        ></el-image>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-record">
            <span>火箭数据科技有限公司</span>
            <el-divider direction="vertical" />
            <a
                href="https://www.beian.gov.cn/portal/registerSystemInfo?spm=a21bo.jianhua.1997523009.39.5af92a89VpB9nV&recordcode=33021202002094"
                class="footer-line"
            >
                <span class="footer-info">浙公网安备 33021202002094号</span>
            </a>
            <el-divider direction="vertical" />
            <a href="https://beian.miit.gov.cn/" class="footer-line">
                <span class="footer-info">浙ICP备2022012639号-1</span>
            </a>
            <el-divider direction="vertical" />
            <span class="footer-info" >版本信息：v{{ version }}</span>
        </div>
    </div>
</template>

<script>
import { version } from '../../../../package.json';
export default {
    data() {
        return {
            version,
            qrCodeFirst: require("@/assets/qq_.png"),
            qrCodeSecond: require("@/assets/img/mainPageImg/qrCodeSecond.png"),
            phoneIcon: require("@/assets/img/mainPageImg/phoneIcon.png"),
            mobileIcon: require("@/assets/img/mainPageImg/mobileIcon.png"),
            footList: [
                {
                    url: require("@/assets/img/mainPageImg/footIconFirst.png"),
                    title: "多渠道服务支持",
                },
                {
                    url: require("@/assets/img/mainPageImg/footIconSecond.png"),
                    title: "产品持续迭代",
                },
                {
                    url: require("@/assets/img/mainPageImg/footIconThree.png"),
                    title: "安全数据环境",
                },
            ],
        }
    },
    methods: {
        enterProDetail(url) {
            window.open(url, "_blank");
        },
    },
}
</script>
<style lang="stylus" scoped>
.footer {
    background: #414755;
    font-size: 12px;
    color: #fff;
    // min-height: 400px;
    position: relative;
    .foot_touch{
        margin-top: 5px;
        font-size: 14px;
        width:100%;
        display:flex;
        align-items:center;
    }
    .foot_head {
      margin: auto;
      display: flex;
      justify-content: space-around;
      background-color: #1e282d;
      box-sizing: border-box;
      .head_item {
        width: 23%;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        .item_right {
          display: flex;
          flex-direction: column;
          color: white;
          margin-left: 10px;
          h2 {
            font-size: 14px;
            font-weight: 400;
          }
          p {
            margin-top: 8px;
            font-size: 10px;
          }
        }
      }
      .head_item:hover {
        background-color: rgb(36, 35, 35);
      }
    }
    .foot_content {
      display: flex;
      justify-content: space-between;
      padding: 10px 50px 0 100px;
      .footer-title{
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 15px;
      }
        ul li {
          margin-top: 12px;
        }
        ul li:hover {
          color: rgb(0, 153, 255);
        }
      .footer-left {
        display: flex;
        width: 400px;
        justify-content: space-between;
      }
      .footer-right {
        display: flex;
        width: 400px;
        justify-content: space-between;
      }
      .weixin {
        width:100%;
        display:flex;
        justify-content:space-around;
        .qr_img{
            width:80px;
            height:80px;
            &:first-child{
                margin-right: 30px;
            }
        }
      }
    }
    .footer-record{
        height: 40px !important;
        line-height: 40px !important;
        display: flex;
        margin: 0 auto;
        justify-content: center;
        align-items: center;
        a {
            color: #ffffff;
        }
    }
    .footer-line {
        display: inline-block;
        text-decoration: none;
        height: 20px;
        line-height: 20px;
    }
  }
</style>