<template>
    <div :class="{'menu-tip-wrap':true}">
        <div class="triangle-bg" :style="triangleStyle"></div>
        <div class="tip-msg" :style="msgStyle">{{ title }}</div>
    </div>
</template>

<script>
export default {
    name: 'MenuTip',
    props: {
        title: {
            type: String
        },
        color: {
            type: String
        },
        fontSize: {
            type: Number
        },
        width: {
            type: Number
        }
    },
    computed: {
        triangleStyle() { 
            return {
                borderColor: `transparent transparent transparent ${this.color || '#ff551f'}`
            }
        },
        msgStyle() { 
            const cacheStyle = {
                background: `${this.color || '#ff551f'}`,
                fontSize: `${this.fontSize}px`,
                width: this.width ? `${this.width}px` : 'max-content'
            }
            return this.fontFamily ? {
                ...cacheStyle,
                fontFamily: this.fontFamily
            } : cacheStyle
        }
    }
}
</script>

<style lang="stylus" scoped>
.menu-tip-wrap{
    position:relative;
    height:28px;
    border-radius:4px;
    overflow:hidden;
    font-family: "alibaba", sans-serif;
}

.triangle-bg{
    width : 0;
    height: 0;
    border: 13px solid;
    border-color: transparent transparent transparent #ff551f;
    position: absolute;
    top:0;
    left:0;
}
.tip-msg{
    height:20px;
    line-height: 18px;
    padding: 1px 8px;
    position:relative;
    top: 0;
    left : 0;
    background: #ff551f;
    border-radius: 4px;
    overflow: hidden;
    font-size: 12px;
    color: #ffffff;
    z-index: 99;
}
</style>