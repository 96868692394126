// 宁波港物流查询接口
import { request } from "@/utils/request.js";

// 查询历史搜索记录
export function searchHistorySuggestion(param) {
    return request(`/blno/queryLog/getQueryLog`, "get", param, {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    });
}

// 删除搜索历史记录
export function deleteHistorySuggestion(id) {
    return request(`/blno/queryLog/deleteQueryLog?id=${id}`, "get", "", {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    });
}

// 船期添加模糊查询
export function searchShip(param) {
    return request(
        `/vessels/userBlno/getVessels`,
        "post",
        param,
        {},
        false,
        true,
    );
}

// 非订阅查箱单
export function queryBoxlist(param) {
    return request(
        "/blno/userBlno/queryBlnoDetailsNonSubscribe",
        "post",
        param,
        {},
        false,
        true,
    );
}

// 非订阅查进港
export function queryInGates(param) {
    return request(
        "/blno/userBlno/queryInGatesNonSubscribe",
        "post",
        param,
        {},
        false,
        true,
    );
}

// 非订阅查舱单
export function queryManifestNoSub(param) {
    return request(
        "/blno/userBlno/queryManifestNonSubscribe",
        "post",
        param,
        {},
        false,
        true,
    );
}

// 非订阅查船期
export function queryShip(param) {
    return request(
        "/blno/userBlno/queryShipNonSubscribe",
        "post",
        param,
        {},
        false,
        true,
    );
}

// 插入搜索历史记录
export function addHistorySuggestion(param) {
    return request(
        "/blno/queryLog/saveQueryLog",
        "post",
        param,
        {},
        false,
        true,
    );
}

// 根据提单号查询预配单
export function querySimpleManifestInfo(billNo, blnoMain) {
    let baseUrl = `/blno/port/getAllManifest?blno=${billNo}`;
    if (blnoMain) baseUrl += `&blnoMain=${blnoMain}`;
    return request(`${baseUrl}`, "get", "", {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    });
}

// 根据提单号和船名航次查询完整舱单
export function queryFullManifestInfo(billNo, vesselename, voyage) {
    return request(
        `/blno/port/api/getFullManifest?blno=${billNo}&vesselsName=${vesselename}&voyage=${voyage}`,
        "get",
        "",
        {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        },
    );
}

// 获取船期信息
export function queryShipInfo(vesselename, voyage) {
    return request(
        `/blno/port/getVessel?vesselsName=${vesselename}&voyage=${voyage}`,
        "get",
        "",
        {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        },
    );
}

/**
 * 获取订阅提单详情
 * @param {int} id
 */
export function queryDetailBoxInfo(id) {
    return request("/blno/userBlno/queryBlnoDetails", "post", id, {}, true);
}

/**
 * 获取订阅提单详情
 * @param {int} id
 */
export function queryBlnoDetailSH(id) {
    return request("/blno/userBlnoSh/queryBlnoDetails", "post", id, {}, true);
}

export function uploadBlnoExcel(id) {
    return request("/blno/userBlno/v2/uploadBlnoExcel", "post", id, {}, true);
}

/**
 * 订阅修改备注或车牌号
 * @param {number} id
 * @param {string} remark
 * @param {string} truckLicense
 */
export function updateRemarkAndTruck(param) {
    return request(`/blno/userBlno/updateReTr`, "post", param);
}

/**
 * 上海订阅修改备注
 * @param {number} id
 * @param {string} remark
 */
export function remarkShVessels(param) {
    return request(`/vessels/shVessels/updateRemark`, "post", param, {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    });
}

/**
 * 修改船期
 * @param {number} id
 * @param {string} vesselsName
 * @param {string} voyage
 */
export function updateVesselsNameAndVoyage(param) {
    return request("/blno/userBlno/updateVv", "post", param);
}

/**
 * 修改船期
 * @param {number} id
 * @param {string} vesselsName
 * @param {string} voyage
 */
export function updateUserBlnoSH(param) {
    return request("/blno/userBlnoSh/updateVv", "post", param);
}

/**
 * @description  船期订阅(dialog中)
 * @param {string} remark
 * @param {string} vesselsName
 * @param {string} voyage
 */
export function subcribeInfo(param) {
    return request("/vessels/userBlno/subscribe", "post", param);
}
// 上海船期订阅
/**
 * @description  上海船期订阅(dialog中)
 * @param {string} remark
 * @param {string} vesselsName
 * @param {string} voyage
 */
export function shVesselsInfo(param) {
    return request("/vessels/shVessels/subscribe", "post", param);
}

// 获取全部的提单号用于搜索
export function queryBlno(param) {
    return request("/blno/userBlno/queryBlno", "get", param);
}

// 获取上海全部的提单号用于搜索
export function queryBlnoSH(param) {
    return request("/blno/userBlnoSh/queryBlno", "get", param);
}

// 宁波还原历史记录
export function recallDelete(param) {
    return request("/blno/userBlno/recallDeleted", "post", param);
}

// 上海历史还原记录
export function recallDeleteSH(param) {
    return request("/blno/userBlnoSh/recallDeleted", "post", param);
}

/**
 * 删除订阅提单
 * @param {array} param
 */
export function boxDelete(param) {
    return request("/blno/userBlno/deleteList", "post", param);
}
/**
 * 上海删除订阅船期
 * @param {array} param
 */
export function shVesselsDelete(param) {
    return request("/vessels/shVessels/deleteList", "post", param);
}

/**
 * 手动刷新物流订阅（宁波）
 * @param {string} blno
 */
export function manualRefresh(param) {
    return request(
        "/blno/userBlno/updateBlnoByUser",
        "post",
        param,
        {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        },
        true,
        false,
    );
}
