<template>
    <div v-if="!item.hidden">
        <!-- 渲染普通菜单 -->
        <el-menu-item
            v-if="
                !item.children ||
                !(item.children.length > 0) ||
                item.meta.hiddenChild
            "
            class="hvr-bounce-to-bottom"
            :index="resolvePath(item.path)"
            :style="imgMenu ? { padding: '0 14px' } : {}"
        >
            <span v-if="!imgMenu">{{ item.meta.title }}</span>
            <el-image
                v-else-if="imgMenu && imgSrc"
                class="img_menu_item"
                :src="imgSrc"
            ></el-image>
        </el-menu-item>
        <el-submenu
            v-else
            class="hvr-bounce-to-bottom"
            ref="subMenu"
            :index="item.children[0].path"
            popper-append-to-body
        >
            <template slot="title">{{ item.meta.title }}</template>
            <template>
                <el-menu-item
                    class="el-menu-item"
                    v-for="submenu in item.children"
                    :key="submenu.path"
                    :index="resolvePath(submenu.path)"
                >
                    {{ submenu.meta.title }}
                </el-menu-item>
            </template>
        </el-submenu>
    </div>
</template>

<script>
import path from "path";
import AppLink from "./Link";

export default {
    name: "MeanuItem",
    components: { AppLink },
    props: {
        // route object
        item: {
            type: Object,
            required: true,
        },
        isNest: {
            type: Boolean,
            default: false,
        },
        basePath: {
            type: String,
            default: "",
        },
        imgMenu: {
            type: Boolean,
            default: false,
        },
        imgSrc: {
            type: String,
        },
    },
    methods: {
        resolvePath(routePath) {
            return path.resolve(routePath);
        },
    },
};
</script>
<style lang="stylus" scoped>

/deep/ .el-submenu .el-submenu__title .el-submenu__icon-arrow{
  display:none;
}

.el-menu {
    &.page_header_menu{
      margin-left: 20px;
      border-bottom: none;
      .menu_item_wrapper_box_query .el-menu-item{
        color: #FF551F;
      }
      .el-menu-item{
        height:30px;
        line-height:30px;
      }

      .img_menu_item{
        min-width: 50px;
      }
      .hvr-bounce-to-bottom {
        display: inline-block;
        vertical-align: middle;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        position: relative;
        border-radius: 4px;
        overflow: hidden;
        color: #333333;
        border: none;
        font-size:15px;
        font-weight:bold;
        overflow: hidden;
        &.is-active{
          border-radius: 4px 4px 0 0;
          border-bottom: 2px solid #409eff;
        }
      }
      .hvr-bounce-to-bottom:before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgb(193,194,197);
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: 50% 0;
        transform-origin: 50% 0;
        -webkit-transition-property: transform;
        transition-property: transform;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
      }
      .hvr-bounce-to-bottom:hover, .hvr-bounce-to-bottom:focus, .hvr-bounce-to-bottom:active {
        color: #333333;
      }
      .hvr-bounce-to-bottom:hover:before, .hvr-bounce-to-bottom:focus:before, .hvr-bounce-to-bottom:active:before {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
        transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
      }

      ::v-deep.hvr-bounce-to-bottom .el-submenu__title{
        color:#333333;
        font-size:15px;
        font-weight:bold;
      }

      .el-menu--horizontal {
        border-bottom: 0px;
      }

      .analysis-menu-color{
        color:#3564dd;
        font-weight:1200 !important;
      }

    }
  }
</style>
