<template>
    <NotesDialog
        width="750px"
        :visible.sync="visibleSync"
        top="5vh"
        @know="handleKnow"
    >
        <div class="notice">
            <div class="center"><h1>版本更新</h1></div>
            <div class="notice-content">
                <div class="line">致各位放舱网的用户：</div>
                <div class="line">为了给您提供更优质的服务，放舱网于 2025 年 03 月 31 日进行了更新。</div>
                <div class="line bold">更新内容：</div>
                <div class="line">- 新增自定义列宽功能</div>
                <div class="line">- 列展示内容调整</div>
                <div class="line bold">自定义列宽使用说明：</div>
                <div><img src="./imgs/notes-9/1.jpg" width="700" /></div>
                <div class="line bold">列展示内容调整：</div>
                <div><img src="./imgs/notes-9/2.jpg" width="700" /></div>
                <div class="line bold">物流节点整合：</div>
                <div><img src="./imgs/notes-9/3.jpg" width="700" /></div>
                <div><img src="./imgs/notes-9/4.jpg" width="700" /></div>
            </div>
        </div>
    </NotesDialog>
</template>

<script lang="ts">
import NotesDialog from './NotesDialog.vue';

export default {
    components: {
        NotesDialog,
    },
    data() { 
        return {
            visibleSync: false
        }
    },
    methods: {
        open() { 
            this.visibleSync = true
        },
        handleKnow() { 
            this.visibleSync = false;
            this.$emit('know')
        }
    }
}
</script>