// 国外跟踪的接口
import { request } from "@/utils/request.js";

/**
 * @description 基于提单号检测船司
 * @param {string} billNo 提单号
 * @param {string} portCode 港口
 */
export function checkCarrierByBillNo(data) {
    return request(
        "/shipping/checkShip",
        "post",
        data,
        {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        },
        true,
    );
}

/**
 * @description 查询提单信息(国外)
 * @param {string} billNo 提单号
 * @param {string} shipCode 船司
 */
export function getBillNoInfoAbroad(data) {
    return request(
        "/shipping/getBillNoInfo",
        "post",
        data,
        {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        },
        true,
    );
}

/**
 * @description 重新订阅国外(宁波)
 * @param {number} id 订阅id
 * @param {string} shipCode 船司
 * @param {string} port 港口 // NB、SZ、XM、QD、TJ
 */
export function subAbroadById(data) {
    const { port, ...restData } = data;
    // port为大写字母，转为首字母大写，后面的字母小写
    const newPort =
        port === "NB"
            ? ""
            : port.charAt(0).toUpperCase() + port.slice(1).toLowerCase();
    return request(
        `/blno/userBlno${newPort}/subscribeAbroad`,
        "post",
        restData,
        {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        },
        true,
    );
}

/**
 * @description 刷新
 * @param {string} shipCode 船司
 * @param {string} portCode
 */
export function refreshAbroad(data) {
    return request(
        "/shipping/autoUpdate",
        "post",
        data,
        {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        },
        true,
    );
}

/**
 * @description 获取支持的船公司
 */
export function getSupportCompanyList() {
    return request("/shipping/getShipCompanies", "get");
}

/**
 * @description 修改船公司
 * @param {Number} id 船公司id
 * @param {String} portCode 港口代码
 * @param {String} shipCode 船公司代码
 */

export function updateShipCompany(data) {
    return request(
        "/shipping/updateShip",
        "post",
        data,
        {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        },
        true,
    );
}
